@import "@/theme.scss";

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// @import "./bubble.css";
.node circle {
  // stroke: "#e2349";
  // stroke-width: 2px;
}
.bubblebox {
  width: 100%;
  height: 100%;

  .color_base {
    // color: #333333 !important;
    // fill: #333333 !important;
    @include baseColor() // fill: #fff !important;
      @include fillColor();
  }

  .filled {
    // fill: url(#mainGradient);
  }

  .filled:hover {
    // fill: url(#mainGradientLight);
  }

  .circleDescr {
    // fill: transparent;
    // fill: red;
    // fill: #191919;
    @include bubblefill();
  }
  .activeCircle {
    // fill: #467fae !important ;
    @include bubbleActivefill();
  }
  object.svgWrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
  }

  object.svgWrapper svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .textDetails,
  .textDetails * {
    background: transparent;
    font-size: inherit;
    z-index: -1;
    pointer-events: none;
  }
  .textDetails {
    display: none;
  }

  .detailsContent {
    // position: absolute;
    height: 100%;
    width: 100% !important;
    margin: 0;
    padding: 0;
    text-align: center;
    overflow: visible;
    .contentTitle {
      font-size: 2.5rem;
      opacity: 0.8;
      margin-top: 11rem;
      text-align: center;
      @include baseColor();
    }
  }

  .detailsContent .beatsCountWrapper {
    position: absolute;
    width: 100%;
    top: 36%;
    .beatsSub {
      // color: #fff;
      @include baseColor();
    }
  }

  .detailsContent .contentBody {
    margin-top: 2rem;
    font-weight: bold;
  }

  .detailsContent .beatsSplit {
    width: 100%;
    position: absolute;
    bottom: 110px;
    height: 80px;
    .split_box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .news_box {
      }
      .separator {
        width: 2px;
        height: 40px;
        margin: 0 10px;
        content: " ";
        overflow: visible;
      }
      .blog_box {
        // margin-right: 90px;
      }
    }
  }

  .empty_data {
    // position: absolute;
    // height: 100%;
    // opacity: 0;
    // width: 100%;
    // display: flex;
    // align-content: center;
    // justify-items: center;
    // z-index: 1;
    // /* pointer-events: none; */
    // transition: opacity 0.2s;
  }

  .empty_data.clickMessage {
    // background: rgba(0, 0, 0, 0.6);
    // color: white;
  }
  .empty_data.show {
    // opacity: 1;
  }

  .empty_data span {
    // flex: 1 1 auto;
    // text-align: center;
    // align-self: center;
    // font-size: 1.2rem;
    // letter-spacing: 0.5rem;
  }
  #circle_chart {
    position: absolute;
    // height: 100%;
    height: 90%;
    width: 100%;
    z-index: 10;
  }
  #circle_chart,
  .sliderWrapper {
    transition: opacity 0.2s;
  }
  #circle_chart.hide {
    opacity: 0;
  }
  .sliderWrapper.show {
    opacity: 1;
  }
  .medium-break .circle_feedback,
  .small-break .circle_feedback {
    line-height: 1.2rem;
    display: block;
  }
}
