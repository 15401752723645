@import "@/theme.scss";






































































































































































































































































.page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themeBg1();
}
.jlsubjective {
  width: 100%;
  @include themeBg1();
  .titlebox {
    display: flex;
    height: 40px;
    align-items: center;
    margin-left: 10px;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      @include fontColor();

      margin-left: 20px;
      cursor: pointer;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
  .imgbox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 176px;
      height: 176px;
      margin: 0 auto;
    }
  }
  .numbox {
    height: 30px;
    line-height: 30px;
    color: #fff;
  }
  .switchbtn {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .all {
      margin-left: 20px;
      cursor: pointer;
    }
    .zh {
      margin-left: 40px;
      cursor: pointer;
    }
    .en {
      margin-left: 40px;
      cursor: pointer;
    }
    .activeicon {
      color: #fff !important;
    }
  }
}
