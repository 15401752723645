/*
*  主题基础背景色值
*  
*/
$bg_color:#222;
$bg_color2:#fff;
$bg_color3:#f8f8ff;
$bg_color4:#3a3a3a;
$f_color:#b4b4b4;
$f_color2:#6d6d6d;
// 问号的背景
$bg_color5:#C9C9CD;
// tab 
$f_color3:#bababa;
$f_color4:#222;

@mixin fontColor {
  [data-theme="dark"] & {
    color: $f_color;
  }
  [data-theme="light"] & {
    color: $bg_color;
  }
}
@mixin fontColor2 {
  [data-theme="dark"] & {
    color: $f_color2;
  }
  [data-theme="light"] & {
    color: $bg_color;
  }
}
// tab
@mixin fontColor3 {
  [data-theme="dark"] & {
    color: $f_color3 !important;
  }

  [data-theme="light"] & {
    color: $f_color4 !important;
  }
}

// 详情
@mixin fontColor4 {
  [data-theme="dark"] & {
    color: #fff !important;
  }

  [data-theme="light"] & {
    color: #000 !important;
  }
}


@mixin tabBgLineColor {
  [data-theme="dark"] & {
    color: #fdca64 !important;
  }

  [data-theme="light"] & {
    color: #0BA3F1 !important;
  }
}
@mixin tabFontColor {
  [data-theme="dark"] & {
    color: #fff !important;
  }

  [data-theme="light"] & {
    color: #0BA3F1 !important;
  }
}
@mixin tabBgColor {
  [data-theme="dark"] & {
    background-color: #fdca64 !important;
  }

  [data-theme="light"] & {
    background-color: #0BA3F1 !important;
  }
}
@mixin tabBorderColor {
  [data-theme="dark"] & {
    border-color: #fdca64 !important;
  }

  [data-theme="light"] & {
    border-color: #0BA3F1 !important;
  }
}
@mixin themeBg {
  [data-theme="dark"] & {
    background: #222;
  }

  [data-theme="light"] & {
    background: #fff;
  }
}
@mixin themeBg1 {
  [data-theme="dark"] & {
    background: black;
  }

  [data-theme="light"] & {
    background: #f8f8ff;
  }
}
@mixin themeBg2 {
  [data-theme="dark"] & {
    background: $bg_color4;
  }

  [data-theme="light"] & {
    background: $bg_color5;
  }
}


/*
* 基础色值
*/
@mixin baseColor{
  [data-theme="dark"] & {
    color:#fff !important;
   }
   [data-theme="light"] & {
     background:transparent !important;
   }
}
@mixin fillColor {
  [data-theme="dark"] & {
    fill:#fff !important;
   }
   [data-theme="light"] & {
    // fill:#fff !important;

     background:transparent
   }
}



/*
* header
*/
@mixin headertBg{
  [data-theme="dark"] & {
   background:#191919;
  }
  [data-theme="light"] & {
   background:#191919;
  }
}

/*
* bubble
*/
@mixin bubblefill{
  [data-theme="dark"] & {
   fill:#191919;
  }
  [data-theme="light"] & {
   fill:white !important;
    // background:transparent;
  }
}
@mixin bubbleActivefill{
  [data-theme="dark"] & {
  //  fill:#191919;
  fill: #467fae !important ;
  }
  [data-theme="light"] & {
  fill: #467fae !important;

    // background:transparent !important;
  }
}

@mixin bubbleBg{
  [data-theme="dark"] & {
   background:#191919;
  }
  [data-theme="light"] & {
    background:transparent !important;
  }
}

//动态新闻
@mixin news_fontColor1 {
  [data-theme="dark"] & {
    color: #fdca64;
  }

  [data-theme="light"] & {
    color: #D90000 !important;
  }
}
@mixin news_fontColor2 {
  [data-theme="dark"] & {
    color: #fdca64;
  }

  [data-theme="light"] & {
    color: #D90000 !important;
  }
}


