@import "@/theme.scss";







































































































































































































































































































































































































































































































.gsap_progress {
  // width: 400px;
  // height: 300px;
  // background: #191919;
  @include themeBg1();
  position: relative;
  .titlebox {
    position: absolute;

    left: 10px;
    top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      color: #b4b4b4;
      margin-left: 20px;
      z-index: 999;
      cursor: pointer;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }

  .news-attention-container .tcl-bottom-container {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    .pubNumber {
      // color: #fff;
      @include fontColor2();

      #pubNumber {
        color: #33a8ee;
      }
    }
  }
  .news-attention-container svg {
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  .news-attention-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    flex: 1;
    position: relative;
  }

  path#arcSpeedo {
    transition: transform 700ms ease;
    -o-transition: transform 700ms ease;
  }

  #tcl-gauge-svg .pointer path {
    transition: transform 200ms ease;
    -o-transition: transform 200ms ease;
  }

  #tcl-gauge-svg path.selectableArc {
    transition: opacity 0.3s;
    opacity: 0.4;
  }
  svg#tcl-gauge-svg {
    height: auto;
    width: 100%;
    flex: 0 1 auto;
    margin-top: 10px;
  }
  #tcl-gauge-svg g {
    transition: transform 0s;
  }
  #tcl-gauge-svg path.iconRef {
    opacity: 0;
    transition: opacity 200ms;
  }
  #tcl-gauge-svg path.selected,
  #tcl-gauge-svg path.iconRef.show {
    opacity: 1;
  }

  #tcl-gauge-svg rect {
    transition: fill 200ms;
  }

  #tcl-gauge-svg text.labelRef {
    transition: transform 200ms;
    text-anchor: middle;
    dominant-baseline: middle;
  }

  #tcl-gauge-svg path.hidden {
    display: none;
  }

  #tcl-gauge-svg path.show {
    display: initial;
  }

  #tcl-gauge-svg .pointer path {
    transition: transform 200ms ease;
    -o-transition: transform 200ms ease;
  }
}
