@import "@/theme.scss";




































































































































.jlrank {
  width: 100%;
  // background: black;
  // background: #191919;
  @include themeBg1();

  .titlebox {
    display: flex;
    align-items: center;
    .title {
      margin-left: 10px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      @include fontColor();

      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
  .rank {
    padding: 0 10px;
    ul {
      height: 180px;
      // padding: 0 10px;
      overflow: auto;
      li {
        // height: 20px;
        .title {
          display: flex;
          align-items: center;
          .point {
            width: 20px;
            span {
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              // background: #b4b4b4;
              @include fontColor2();
            }
          }
          .content {
            // color: #fff;
            @include fontColor2();

            font-size: 14px;
          }
        }
        .progress {
          display: flex;
          height: 14px;
          // align-items: center;
          // .point {
          //   width: 20px;
          //   height: 5px;
          //   background: #fff;
          // }
          .content {
            width: 90%;
            .el-progress-bar__outer {
              background-color: transparent !important;
            }
            .el-progress__text {
              // color: #fff;
              @include fontColor2();
            }
          }
        }
      }
    }
  }
}
