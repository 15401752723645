@import "@/theme.scss";
































































































































































@import "./default.css";
@import "./iconfont/iconfont.css";
@import "./selficonfont/iconfont.css";
// @font-face {
//   font-family: "iconfont";
//   src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834');
//   src: url('https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834#iefix') format('embedded-opentype'),
//     url('https://at.alicdn.com/t/font_985780_km7mi63cihi.woff?t=1545807318834') format('woff'),
//     url('https://at.alicdn.com/t/font_985780_km7mi63cihi.ttf?t=1545807318834') format('truetype'),
//     url('https://at.alicdn.com/t/font_985780_km7mi63cihi.svg?t=1545807318834#iconfont') format('svg');
// }
// @mixin themeBg{
//   [data-theme="dark"] & {
//    background:#222;
//   }
//   [data-theme="bright"] & {
//     background:white
//   }
// }
// @import './theme.scss';
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
}
