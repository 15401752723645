@import "@/theme.scss";

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.timeseries {
  position: relative;
  // background: black;
  // background: #191919;
  @include themeBg1();

  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  .titlebox {
    position: absolute;

    left: 10px;
    top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      @include fontColor();

      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
  .numbox {
    position: absolute;
    left: 30px;
    bottom: 10px;
    // color: #b4b4b4;
    @include fontColor();

    font-size: 12px;
    text-align: left;
    margin-left: 20px;
    .num {
      color: #33a8ee;
    }
  }
  .shadow {
    text-align: center;
    .svgg {
      margin: 0 auto;
    }
  }
  #tcl-chart-widget {
    height: 100%;
    width: 100%;
  }
  .legend {
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    height: 40px;
    line-height: 40px;
    width: 100%;
  }

  .legend > div {
    // flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  .legend .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    align-self: center;
    display: inline-block;
  }

  .legend .descr {
    margin: 0 0.5rem;
    // color: #fff;
    @include fontColor();
  }
  .legend .dot::after {
    content: " ";
  }

  .legend .hide {
    display: none;
  }
  .tcl-chart-container {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    height: 100%;
    width: 100%;
  }

  .brush .extent {
    fill-opacity: 0.07;
    shape-rendering: crispEdges;
    clip-path: url(#clip);
  }

  #tcl-chart-widget .pane {
    cursor: move;
    fill: none;
    pointer-events: none;
  }
  /* brush handles  */
  .resize .handle {
    fill: #555;
  }

  .resize .handle-mini {
    fill: white;
    stroke-width: 1px;
    stroke: #555;
  }

  .scale_button {
    cursor: pointer;
  }
  .scale_button rect {
    fill: #eaeaea;
  }
  .scale_button:hover text {
    fill: #417dd6;
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}
