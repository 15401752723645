@import "@/theme.scss";











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// .iconfont {
//   font-family: "iconfont" !important;
//   font-size: 16px;
//   font-style: normal;
//   color:#0BA3F1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
.hk {
  width: 100%;
  height: 100%;
  .popbox {
    position: absolute;
    left: 20px;
    top: 60px;

    .pop {
      width: 1200px;
      // min-height: 300px;
      height: 800px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 260px;
      }
    }
    .btnbox {
      display: flex;
      justify-content: center;
      p {
        margin-left: 20px;
      }
    }
  }
  .el-aside {
    width: 200px !important;
    .oper_bt {
      background: #0ba3f1;
      color: #fff;
      border-radius: 10px;
      width: 72px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
    }
    .selected {
      ul > li {
        margin-top: 20px;
        color: #b4b4b4;
      }
    }
  }
  .mainview {
    margin-top: 20px !important;
  }
  .el-main {
    display: flex;

    .main_box {
      flex: 6;
      position: relative;

      .control {
        position: absolute;
        right: 0px;
        top: 60px;
        background: transparent;
        z-index: 99;
        .time {
          // padding-right: 20px;
          z-index: 99;

          .timeline {
            // width: 120px;
            .el-radio,
            .el-radio--medium.is-bordered .el-radio__label {
              font-size: 14px;
              display: flex;
              flex-direction: row-reverse;
            }
            .el-timeline-item {
              .el-timeline-item__content {
                color: #fff !important;
              }
            }
            .el-col {
              height: 20px !important;
              .el-radio__label {
                font-size: 18px !important;
                display: inline-block !important;
                width: 80px !important;
              }
              .el-radio__inner {
                background-color: #5b5b5b !important;
                border-color: #5b5b5b !important;

                // @include tabBgColor();
                // @include tabBorderColor();
              }
              .el-radio__input.is-checked + .el-radio__label {
                // color: #fdca64 !important;
                @include tabBgLineColor();
              }

              .el-radio__input.is-checked .el-radio__inner {
                // border-color: #fdca64 !important;
                // background: #fdca64 !important;
                @include tabBorderColor();
                @include tabBgLineColor();
              }
            }
            .line {
              height: 14px;
              border-right: 1px solid rgb(139, 128, 128);
              margin-right: 6px;
              margin-bottom: 6px;
              .content {
                color: #b4b4b4 !important;
              }
            }
          }
        }
        .zoombox {
          margin-right: 20px;
          .c {
            font-size: 20px;
            color: #fff;
            cursor: pointer;
            text-align: right;
            padding-right: 12px;
          }
          .add {
          }
          .progress {
            height: 160px;
            width: 150px;
            display: flex;
            justify-content: flex-end;
            // margin-right: 20px;
            .el-slider__runway {
              background-color: #5b5b5b !important;
            }
          }
          .increase {
            font-size: 30px;
          }
        }
      }

      .subbtn {
        position: absolute;
        right: 0;
        top: 60px;

        // padding: 5px;
        padding: 10px 0px 10px 10px;

        text-align: center;

        z-index: 999;
        width: 15px;
        height: 35px;
        line-height: 35px;
        // border-top-left-radius: 5px;
        // border-bottom-left-radius: 5px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        p {
          background: #0ba3f1;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        // border: 10px solid transparent;
        // border-color: transparent;
      }
      .subbtn:hover {
        cursor: pointer;
      }
      .table {
        display: flex;

        justify-content: flex-end;

        align-items: center;
        border-bottom: 2px solid #535353;

        .iconmenu {
          margin-right: 20px;
          margin-left: 40px;
          color: #fff;
          cursor: pointer;
       
        }
        .iconmenu:hover {
          cursor: pointer;
        }
        .el-tabs__active-bar {
          // background-color: #fdca64 !important;
          @include tabBgColor();
        }
        .el-tabs__item {
          font-size: 22px !important;
          // color: #bababa !important;
          @include fontColor3();
        }
        .is-active {
          // color: #fdca64 !important;
          @include tabBgLineColor();
        }
        .el-tabs__item:hover {
          // color: #fdca64 !important;
          @include tabBgLineColor();
        }
        .el-tabs__nav-wrap::after {
          height: 0 !important;
        }
      
      }
      .main_bubble{
        // width:70%;
        // margin:0 auto;
      }
      .main_bubble {
        // width:70%;
        // margin:0 auto;
      }
      .nodata {
        font-size: 20px;
        color: #fff;
      }
    }
    .sub_main {
      // width: 660px;
      flex: 3;
      position: relative;
      border-left: 1px solid #3a3a3a;
      .subbtn {
        position: absolute;
        left: 0;
        top: 60px;
        background: #0ba3f1;
        color: #fff;
        font-size: 12px;

        width: 15px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 999;
        cursor: pointer;
      }
      .subbtn:hover {
        cursor: pointer;
      }
      .submenu {
        .menuinfo {
          height: 118px;
          text-align: left;
          margin-left: 30px;
          position: relative;
          .company {
            font-size: 24px;
            // color: #fff;
            height: 40px;
            line-height: 40px;
            @include fontColor();

            .num {
              font-size: 20px;
              margin-left: 20px;
            }
          }
          .today_data {
            height: 40px;
            display: flex;
            .nav_nodata {
              color: #fff;
            }
            .nav_data {
              display: flex;
            }
            .percent {
              font-size: 22px;
              color: red;
            }
            .down {
              color: green;
            }
            .currnum {
              color: #fff;
              font-size: 22px;
              margin-left: 30px;
            }
            .from {
              font-size: 18px;
              margin-left: 15px;
              color: #b4b4b4;
            }
          }
          .remark {
            font-size: 22px;
            // color: #fdca64;
            @include tabBgLineColor();
          }
          .godetail {
            position: absolute;
            top: 10px;
            right: 20px;
            .star {
              margin-left: 20px;
              font-size: 20px;
              // color: red;
              color: #fff;
            }
            .greystar {
              padding-right: 4px;
            }
            .staron {
              font-size: 26px;
              color: #ff7373;
            }
            .content {
              color: #fff;
              margin-left: 20px;
              background: #0ba3f1;
              border-radius: 5px;
              padding: 4px 8px;
              font-size: 14px;
              cursor: pointer;
            }
          }
          .godetail:hover {
            cursor: pointer;
          }
        }
        .chartbox {
          width: 100%;
          .el-tabs__active-bar {
            // background-color: #fff !important;
          }
          .el-tabs__item {
            font-size: 24px !important;
            // color: #bababa !important;
            min-width: 200px !important;
            @include fontColor3();
          }
          .is-active {
            // color: #fff !important;
            @include tabFontColor();
            font-weight: 500;
          }
          .el-tabs__item:hover {
            // color: #fdca64 !important;
          }
          .el-tabs__nav-wrap::after {
            background-color: #3a3a3a !important;
          }
          .news {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              flex: 1;
              margin-left: 10px;
            }
            .first {
              margin: 0;
            }
          }
          .mg_10 {
            margin-top: 10px;
          }
          .dropbox {
            display: inline-block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            background: grey;
            text-align: center;
            color: #fff;
            .el-icon--right {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}
.hk::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.hk::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
  background: #535353;
}
.hk::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
  border-radius: 10px;
  // background: #ededed;
  background: black;
}
