@import "@/theme.scss";




































































































































































































































































































































































































.page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themeBg1();
}
.circleprogress {
  width: 100%;

  position: relative;
  .titlebox {
    display: flex;

    height: 30px;
    align-items: center;
    margin-left: 10px;
    // margin-top: 6px;
    .title {
      font-size: 16px;
      // color: #b4b4b4;
      font-weight: bold;
      @include fontColor();
    }
    .notice {
      font-size: 12px;
      // color: #b4b4b4;
      margin-left: 20px;
      cursor: pointer;
      @include fontColor();

      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }

  .center {
    width: 100%;

    position: relative;
  }
  .center_circle {
    position: absolute;
  }

  .icontest {
    position: absolute;
    top: 110px;
    left: 110px;
    width: 80px;
    height: 60px;
    // margin-left: -40px;
    margin-top: -30px;
  }
  .numbox {
    height: 30px;
    line-height: 30px;

    color: #fff;
  }
  .switchbtn {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    div {
      span {
        color: #fff;
      }
    }
    .all {
      margin-left: 20px;
      cursor: pointer;
    }
    .zh {
      margin-left: 40px;
      cursor: pointer;
    }
    .en {
      margin-left: 40px;
      cursor: pointer;
    }
    .activeicon {
      color: #fff !important;
    }
  }
}
#circle_progress_iconbox {
  width: 10px;
  height: 140px;
  position: absolute;
  right: 20px;
  top: 50px;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    li {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      span {
        display: inline-block;
        width: 4px;
        height: 20px;
      }
      p {
        display: inline-block;
        width: 4px;
        height: 20px;
      }
    }
    .iconbox {
      margin-left: -2px;
    }
    .red {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;

      p {
        background: #fc2e37;
      }
    }
    .orange {
      span {
        background: #fd9297;
      }
    }
    .blue {
      span {
        background: #f39c00;
      }
    }
    .green {
      span {
        background: #25ff83;
      }
    }
    .blue1 {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      span {
        background: #00d25b;
      }
    }
  }
}
