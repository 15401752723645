@font-face {
  font-family: "iconfontyuqing"; /* Project id 3902847 */
  src: url('iconfont.woff2?t=1676964622511') format('woff2'),
       url('iconfont.woff?t=1676964622511') format('woff'),
       url('iconfont.ttf?t=1676964622511') format('truetype');
}

.iconfontyuqing {
  font-family: "iconfontyuqing" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-yqleidian:before {
  content: "\e601";
}

.icon-yqtaiyang:before {
  content: "\e611";
}

.icon-yqshandian:before {
  content: "\e60f";
}

.icon-yqtaiyang1:before {
  content: "\e69f";
}

.icon-yqwuyunxiayu:before {
  content: "\e63c";
}

.icon-yqdiqiuquanqiu:before {
  content: "\e76c";
}

.icon-yqicon:before {
  content: "\e693";
}

.icon-yqduihua:before {
  content: "\e620";
}

.icon-yqzhongyingwenqiehuan:before {
  content: "\e610";
}

.icon-yqfile:before {
  content: "\e6d3";
}

.icon-yqfuhao-yingwen:before {
  content: "\e712";
}

.icon-yqduihua1:before {
  content: "\e626";
}

.icon-yqwenbenwendang:before {
  content: "\e61f";
}

.icon-yqquanqiu:before {
  content: "\e62d";
}

.icon-yqwenben_o:before {
  content: "\eb4e";
}

.icon-yqyingwen:before {
  content: "\e6c0";
}

.icon-yqzhongwen:before {
  content: "\e6c5";
}

.icon-yqshejiao-duihuakuang:before {
  content: "\e625";
}

.icon-yqfuwenbenkuang:before {
  content: "\e600";
}

