@import "@/theme.scss";




















































































































































































































.jlmainrank {
  width: 60%;
  margin: 0 auto;
  .rank {
    display: flex;
    ul {
      // width: 1000px;
      // height: 1000px;
      height: 800px;
      margin: 0 auto;
      overflow: auto;
      li {
        display: flex;
        align-items: center;
        height: 40px;
        .title {
          width: 340px;
          font-size: 22px;
          // color: #fff;
          text-align: right;
          margin-right: 40px;
          display: flex;
          justify-content: space-between;
          @include fontColor();

          .name {
            text-align: left;
            width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .star_box {
            .star {
              margin-left: 20px;
              font-size: 20px;
              // color: red;
            }
            .greystar {
              padding-right: 4px;
            }
            .staron {
              font-size: 26px;
              color: #ff7373;
            }
          }
        }
        .progress {
          display: flex;
          align-items: center;
          width: 400px;
          height: 100%;
          .news {
            // background: #3d8ae7;
            background: linear-gradient(to right, #f39c02, #e42349);
            height: 10px;
          }
          .news.very-negative {
            background: #25ff83;
          }
          .news.negative {
            background: #00d25b;
          }
          .news.neutral {
            background: #f39c00;
          }
          .news.positive {
            background: #fd9297;
          }
          .news.very-positive {
            background: #fc2e37;
          }
          .news.less {
            background: #0097fe;
          }
          .news.equal {
            background: #929292;
          }
          .news.more {
            background: #e42349;
          }

          // .mind {
          //   background: red;
          // }
          // .trend {
          //   background: chartreuse;
          // } .blog {
          // background: #fb9f06;
          // background: linear-gradient(to right, #f39c02, #e42349);

          // height: 10px;
          // }
          .blog_mind {
            // background: bisque;
          }
          .blog_trend {
            // background: burlywood;
          }
        }
        .total {
          margin-left: 40px;
          font-size: 22px;
          // color: #fff;
          @include fontColor();
        }
      }
    }
    .rankitem {
      padding-right: 20px;
      li:hover {
        // height: 50px;
        background: cornflowerblue;
        cursor: pointer;
      }
    }

    .rankitem::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    .rankitem::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(60, 50, 153, 0.2);
      background: #535353;
    }
    .rankitem::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 6px rgba(23, 10, 139, 0.2);
      border-radius: 10px;
      // background: #ededed;
      background: black;
    }
  }
}
