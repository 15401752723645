@import "@/theme.scss";






































































































































































































































































































































































































































































.stackbarbox {
  position: relative;
  width: 100%;
  // background: black;
  // background: #191919;
  @include themeBg1();
  display: flex;
  justify-content: center;
  align-items: center;

  .tipbox {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 6px;

    // color: #b4b4b4;
    z-index: 999;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    @include fontColor();

    .help {
      margin-left: 20px;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        // background: #3a3a3a;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
}
