@import "@/theme.scss";















































































































































































































































































































































.ringbox {
  position: relative;
  // background: black;
  // background: #191919;
  @include themeBg1();

  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .tipbox {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 6px;

    // color: #b4b4b4;

    z-index: 999;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    @include fontColor();
    .help {
      margin-left: 20px;
      .el-button {
        width: 16px !important;
        height: 16px !important;
        line-height: 16px;
        // color: #6d6d6d;
        border-radius: 50%;
        border: none;
        padding: 0;
        @include fontColor2();
        @include themeBg2();
        // background: #3a3a3a;
      }
      .pop_tipbox {
        border: 1px solid #5a5a5a !important;
        // color: #fff !important;
        // background: #303030 !important;
        @include fontColor2();
        @include themeBg2();
      }
    }
  }
}
